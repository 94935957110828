.scene {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  perspective: 15px;
  perspective-origin: 50% 50%;
  left: 50%;
  transform: rotate(45deg);
  animation: rotate 72s infinite linear;

  .wrap {
    position: absolute;
    width: 1000px;
    height: 1000px;
    left: -500px;
    top: -500px;
    transform-style: preserve-3d;
    animation: move 48s infinite linear;
    animation-fill-mode: forwards;

    &:nth-child(2) {
      animation: move 48s infinite linear;
      animation-delay: 6s;

      .wall {
        animation-delay: 6s;
      }
    }

    .wall {
      width: 100%;
      height: 100%;
      position: absolute;
      // background: url(https://images.unsplash.com/photo-1527224103471-559916697f3a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTg1fHxyZWR8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60);
      background-size: cover;
      opacity: 0;
      animation: fade 48s infinite linear;

      &.right {
        transform: rotateY(90deg) translateZ(500px);
      }

      &.left {
        transform: rotateY(-90deg) translateZ(500px);
      }

      &.top {
        transform: rotateX(90deg) translateZ(500px);
      }

      &.bottom {
        transform: rotateX(-90deg) translateZ(500px);
      }

      &.back {
        transform: rotateX(180deg) translateZ(500px);
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes move {
  0% {
    transform: translateZ(-500px) rotate(45deg);
  }
  100% {
    transform: translateZ(500px) rotate(0deg);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
