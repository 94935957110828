.container {
  width: 100vw;
  height: calc(100vh - 8rem);
  position: absolute;
  top: -100vh;
  left: 0;
  z-index: 4;

  opacity: 0;
  pointer-events: none;
  margin-bottom: -100rem;
  
  transition: opacity 300ms ease-out, top 300ms ease-out;
  
  &.show {
    opacity: 1;
    pointer-events: all;
    top: 0;
  }
}

.dialog {
  width: 100%;
  max-width: 32rem;
  
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: left;

  background: var(--c-background-100);
  border-radius: 2.4rem;
  box-shadow: 0px 16px 16px 0px #00000040;
}

.drag-handle {
  position: absolute;
  top: 0;
  right: 50%;
  transform: translateX(50%);
  width: 4.8rem;
  height: 4.8rem;
  padding: 1.2rem;
  background: transparent;
  border: none;

  cursor: move; 
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.btn-close {
  width: 4.8rem;
  height: 4.8rem;
  border: none;
  border-radius: 0;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 1.2rem;
    height: 1.2rem;
    stroke: var(--c-element-100);
  }
}

.content {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  row-gap: 0.8rem;

  padding-bottom: 4.8rem;
}

.search-form {
  position: relative;
  width: 100%;
  display: flex;

  padding: 1.6rem;

  input[type='search'] {
    z-index: 1;
    flex: auto;
    border: none;
    border-radius: 0;
    background: var(--c-background-200);
    color: var(--c-element-100);
    padding: 0 1.2rem;

    &:focus,
    &:hover {
      filter: none;

      &::-webkit-search-cancel-button,
      #search-clear {
        -webkit-appearance: none;

        height: 2.2rem;
        width: 2.2rem;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
        cursor: pointer;
      }
    }
  }

  button {
    z-index: 1;
    width: 4.8rem;
    height: 4.8rem;

    background: var(--c-background-200);
    border: none;

    .icon {
      stroke: var(--c-element-100);
      fill: none;
    }
  }

  .error {
    z-index: 0;
    position: absolute;
    bottom: 2.8rem;
    left: 1.6rem;
    right: 1.6rem;
    background: var(--c-candy-red-800);

    text-align: center;
    font-size: 1.4rem;

    opacity: 0;
    pointer-events: none;

    transition: opacity 3000ms ease, bottom 300ms ease;

    &.show {
      opacity: 1;
      bottom: -1rem;
      pointer-events: all;
    }
  }
}

.thumbs-list {
  position: relative;

  width: 100%;
  height: 36rem;
  overflow-y: auto;

  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.4rem;

  padding: 0 1.6rem 3.6rem;

  & > .button {
    position: relative;
    width: 100%;
    height: 10.6rem;

    border: 2px solid var(--c-background-200);
    border-radius: 0.8rem;
    overflow: hidden;
    opacity: 0.5;

    transition: border-color 300ms ease, opacity 300ms ease;

    &.selected {
      border-color: var(--c-candy-red);
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: fixed;
    bottom: 4.8rem;
    left: 0;
    right: 0;
    height: 6rem;

    background: rgb(20, 31, 35);
    background: linear-gradient(0deg, rgba(20, 31, 35, 1) 0%, rgba(20, 31, 35, 0) 100%);
    pointer-events: none;
  }
}

.credits {
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
  z-index: 1;

  a {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

@media screen and (min-width: 1024px) {
  .content {
    padding-bottom: 2.4rem;
  }
}
