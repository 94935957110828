.content {
  position: absolute;
  z-index: 1;
  top: 40%;
  left: 50%;
  width: 250px;
  height: 280px;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  pointer-events: all;
  opacity: 1;
  transition: opacity 300ms ease;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  h1 {
    font-size: 4.2rem;
    line-height: 1;
    color: var(--c-iridium-white);

    margin-bottom: 24px;
    bottom: 50%;

    &.flame {
      color: #ffffff;
      text-shadow: 0 -1px 4px #fff, 0 -2px 10px #ff0, 0 -10px 20px #ff8000, 0 -18px 40px #f00;
    }

    &.neon {
      color: #ffffff;
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #18d8ff, 0 0 30px #49ff18,
        0 0 40px #18d8ff, 0 0 55px #18d8ff, 0 0 75px #18d8ff;
    }

    &.vegas {
      color: #ffffff;
      text-shadow: #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 15px, #ff2d95 0px 0px 20px,
        #ff2d95 0px 0px 30px, #ff2d95 0px 0px 40px, #ff2d95 0px 0px 50px, #ff2d95 0px 0px 75px;
    }

    &.rainbow {
      text-shadow: 0.1px -3.1px #ff3300, 0.3px -6.2px #ff6600, 0.7px -9.3px #ff9900,
        1.3px -12.2px #ffcc00, 2px -15px #ffff00, 2.9px -17.6px #ccff00, 3.9px -20.1px #99ff00,
        5px -22.3px #66ff00, 6.2px -24.3px #33ff00, 7.5px -26px #00ff00, 8.9px -27.4px #00ff33,
        10.4px -28.5px #00ff66, 11.9px -29.3px #00ff99, 13.4px -29.8px #00ffcc, 15px -30px #00ffff,
        16.6px -29.8px #00ccff, 18.1px -29.3px #0099ff, 19.6px -28.5px #0066ff,
        21.1px -27.4px #0033ff, 22.5px -26px #0000ff, 23.8px -24.3px #3300ff, 25px -22.3px #6600ff,
        26.1px -20.1px #9900ff, 27.1px -17.6px #cc00ff, 28px -15px #ff00ff, 28.7px -12.2px #ff00cc,
        29.3px -9.3px #ff0099, 29.7px -6.2px #ff0066, 29.9px -3.1px #ff0033, 30px 0 #ff0000;
    }

    &.florida {
      text-shadow: 0 0 4px #00ffff, 0 0 8px #00ffff, 0 0 16px #ff00ff;
    }

    &.shadow {
      text-shadow: 1px 1px #00000080, 2px 2px #11111180, 3px 3px #22222280, 4px 4px #33333380,
        5px 5px #44444450, 6px 6px #55555550, 7px 7px #66666650, 8px 8px #77777750,
        9px 9px #88888850, 10px 10px #99999930, 11px 11px #aaaaaa30, 12px 12px #bbbbbb30,
        13px 13px #cccccc30, 14px 14px #dddddd30, 15px 15px #eeeeee30;
    }
  }

  .label {
    line-height: 1.2;
    letter-spacing: 1px;
    color: var(--c-element-100);
    background: var(--c-background-100);

    .typed {
      font-size: 1.6rem;
      box-shadow: 0.8rem 0 0 var(--c-background-100), calc(0.8rem * -1) 0 0 var(--c-background-100);
      border: 10px solid var(--c-background-100);

      -webkit-box-decoration-break: clone;
      -ms-box-decoration-break: clone;
      -o-box-decoration-break: clone;
      box-decoration-break: clone;
    }
  }
}

@media screen and (min-width: 420px) {
  .content {
    width: 320px;

    h1 {
      font-size: 5.6rem;
    }

    .typed {
      font-size: 2rem;
    }
  }
}

@media screen and (min-width: 620px) {
  .content {
    width: 360px;
    top: 50%;

    h1 {
      font-size: 6.4rem;
    }
  }
}

@media screen and (min-width: 1024px) {
  .content {
    width: 460px;

    h1 {
      font-size: 8rem;
    }
  }
}
