.tools {
  position: absolute;
  bottom: 0;
  margin-bottom: 2.4rem;
  left: 1.4rem;

  z-index: 1;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  column-gap: 0.8rem;

  .commands {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .button {
      background: transparent;
      border: none;

      width: 4.8rem;
      height: 4.8rem;

      flex-shrink: 0;

      transition: transform 300ms ease-in-out;

      svg {
        width: 60%;
        height: 60%;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      opacity: 0;
      margin-bottom: -10rem;
      pointer-events: none;

      transition: opacity 300ms ease, margin-bottom 300ms ease;

      &.show {
        opacity: 1;
        margin-bottom: 0;
        pointer-events: all;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .tools {
    margin-bottom: 2.4rem;
    z-index: 5;
  }
}
